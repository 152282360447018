import React from 'react'
import { Link } from 'gatsby' 
import Logo from "../images/logo.png"

import "../scss/main.scss"

const NotFoundPage = () => {
  return (
    <section className="not-found">
      <img className="not-found__logo" src={Logo} alt="AF First Aid" />
      <h1 className="not-found__content">404: Page not found!</h1>
      <Link to="/" className="not-found__button">Return to site</Link>
    </section>
  )
}

export default NotFoundPage